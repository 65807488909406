import React, { useState } from 'react'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { themeColor } from '../../../../../Global/Global'
import { useDispatch } from 'react-redux'
import { onDeleteVoice, onSaveVoice } from '../../../../../Redux/Actions/CloneActions'
import { FaCheck } from "react-icons/fa6";
import { useSelector } from 'react-redux'
let music = false
const VoiceRows = ({ curElem, index, fetchVoice, ttsData, setTtsData, cId, setTtsStatus }) => {

    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth)
    const [play, setPlay] = useState(false)
    const [loaderUse, setLoaderUse] = useState({
        save: false
    })
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: curElem.type
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteVoice(data, fetchVoice, setSweet))
    }

    const handlePlay = () => {
        music = new Audio(curElem.url)
        music.play()
            .then(r => {
                setPlay(true)
            }
            )
            .catch(err => console.log(err));
        music.onended = () => {
            setPlay(false)
        }
    }

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }


    const handleUseUpload = () => {
        let newData = {
            ...ttsData,
            type: "voiceover",
            src: curElem.url,
            duration: "",
        }
        setLoaderUse(true)
        handleUse(newData)
    }

    const handleUse = (ttsDataVal) => {
        let data = { voice: { ...ttsDataVal } }
        data.id = cId
        setLoaderUse({
            ...loaderUse,
            save: true
        })
        dispatch(onSaveVoice(data, setTtsStatus, loaderUse, setLoaderUse))
    }


    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.name}</td>
            <td className='text-capitalize'>{curElem.type}</td>
            <td style={{ fontSize: 13, fontWeight: "500" }}>{curElem.created}</td>
            <td className='d-flex align-items-center'>
                {+auth.user.isClientAccount === 1 ? null :
                    loaderUse.save ? <i className='fa fa-spinner fa-spin me-2' style={{ color: themeColor, fontSize: "20px" }} /> :
                        <FaCheck style={{ color: themeColor, fontSize: "20px", cursor: "pointer" }} title='Use' className='me-2' onClick={handleUseUpload} />
                }
                {play ?
                    <MdOutlineStopCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                    <MdOutlinePlayCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Play' onClick={handlePlay} />
                }
                {+auth.user.isClientAccount === 1 ? null :
                    <i className="fa-solid fa-trash-can ms-2" style={{ color: themeColor, fontSize: "15px", cursor: "pointer" }} onClick={onDelete}></i>
                }
            </td>

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br> <br> You want to delete this voice?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </tr>
    )
}

export default VoiceRows