const initialState = {
    telegram: [],
    twilio: [],
    stripe: [],
    paypal: [],
    autoresponder: [],
    slack: [],
    discord: [],
    whatsapp: [],
    facebook: []
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return {
                ...state,
                telegram: action.payload.telegram || [],
                twilio: action.payload.twilio || [],
                stripe: action.payload.stripe || [],
                paypal: action.payload.paypal || [],
                autoresponder: action.payload.autoresponder || [],
                slack: action.payload.slack || [],
                discord: action.payload.discord || [],
                whatsapp: action.payload.whatsapp || [],
                facebook: action.payload.facebook || []
            }

        // case 'ADD_SYVID':
        //     return {
        //         ...state,
        //         syvid: action.payload
        //     }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}