import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import Header from '../../../Header/Header';
import TitleBar from '../../../CommonComponents/TitleBar';
import { FiHome, FiUsers } from "react-icons/fi";
import CloneProfile from './CloneProfile';
import CloneBio from './CloneBio';
import CloneSocial from './CloneSocial';
import CloneInstances from './CloneInstances';
import Integrations from './Integration/Integrations';
import Collaborators from './Collaborators';
import Notification from './Notification';
import Messages from './Messages';
import ResponsePreferences from './ResponsePreferences';
import Privacy from './Privacy';
import BillingPlan from './BillingPlan';
import Usage from './Usage';
import Monetization from './Monetization';
import { CgProfile } from "react-icons/cg";
import { FaAddressBook } from "react-icons/fa";
import { RiBrainFill } from "react-icons/ri";
import { HiMiniSquares2X2 } from "react-icons/hi2";
import { FaUserPlus } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";
import { PiNotePencilBold } from "react-icons/pi";
import { RiChatPrivateLine } from "react-icons/ri";
import { BiBookmarkAlt } from "react-icons/bi";
import { useSelector } from 'react-redux';
import { onFetchNewSetting } from '../../../../Redux/Actions/CloneSettingAction';
import { Link, useLocation } from 'react-router-dom';
import queryString from "query-string"
import { useDispatch } from 'react-redux';
import Footer from '../../../Footer/Footer';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { fetchSocialAccounts } from '../../../../Redux/Actions/SocialActions';

const NewSetting = () => {

    const location = useLocation()
    const dispatch = useDispatch();
    const { cId } = queryString.parse(location.search)


    const [loader, setLoader] = useState({
        fetch: true
    });



    const fetchNewSetting = () => {
        let data = {
            cId: cId
        }
        dispatch(onFetchNewSetting(data, loader, setLoader))
    }

    useEffect(() => {
        if (cId) {
            fetchNewSetting();
            dispatch(fetchSocialAccounts(cId));
        }
    }, [cId])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <Header />
                <TitleBar title="Training" />
                <div className="site-wrap" style={{ overflow: "hidden", height: "auto", paddingBottom: "0" }}>
                    <div className="container-fluid">
                        <div className='studio-wrap studio_setting'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className="row">
                                    <div className="col-xl-3 col-md-4 col-12">
                                        <div className="setting_side_wrap settingWrap">
                                            <div className='d-flex justify-content-between flex-lg-row  flex-column'>
                                                <h2 className='mb-lg-5 mb-2'>Settings</h2>

                                                <Link to={`/clone-nav?cId=${cId}`} className='theme-btn me-0 mb-lg-0 mb-2' style={{ height: "40px", }}> <span>  <FaArrowLeftLong style={{ marginRight: "5px" }} />Back</span> </Link>

                                            </div>
                                            <div className='studio-nav'>
                                                <Nav variant="pills" className="flex-column">
                                                    <div>
                                                        <h3 className='mb-3'>Clone settings</h3>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first" ><CgProfile /> Clone Profile</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second"><FaAddressBook />Clone Bio</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="third"><HiMiniSquares2X2 />Clone Socials</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="fourth"><RiBrainFill />Clone Instances</Nav.Link>
                                                        </Nav.Item>
                                                    </div>
                                                    <hr className='mt-1' style={{ opacity: "1", color: "#a1a1aa45" }} />
                                                    <div>
                                                        <h3 className='mb-3 mt-2'>General Settings</h3>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="fifth" ><HiMiniSquares2X2 />Integrations</Nav.Link>
                                                        </Nav.Item>
                                                        {/* <Nav.Item>
                                                            <Nav.Link eventKey="sixth"><FaUserPlus />Collaborators</Nav.Link>
                                                        </Nav.Item> */}

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="eighth"><PiNotePencilBold />Notifications</Nav.Link>
                                                        </Nav.Item>
                                                    </div>
                                                    <hr className='mt-1' style={{ opacity: "1", color: "#a1a1aa45" }} />
                                                    <div>
                                                        <h3 className='mb-3 mt-2'>Conversation Settings</h3>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="ninth" ><AiFillMessage />Messages</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="tenth"><PiNotePencilBold />Response Preferences</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Eleventh"><RiChatPrivateLine />Privacy</Nav.Link>
                                                        </Nav.Item>

                                                    </div>
                                                    <hr className='mt-1' style={{ opacity: "1", color: "#a1a1aa45" }} />
                                                    <div>
                                                        <h3 className='mb-3 mt-2'>Affiliates & Monetization</h3>
                                                        {/* <Nav.Item>
                                                            <Nav.Link eventKey="twelve"><BiBookmarkAlt /> Billing & Plan</Nav.Link>
                                                        </Nav.Item> */}
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="thirteen"><FiUsers /> Usage</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="fourteen" ><FiHome />Monetization</Nav.Link>
                                                        </Nav.Item>
                                                    </div>

                                                </Nav>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-9 col-md-8 mt-md-0 mt-5 col-12">
                                        <div className="setting_right_side_wrap">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <CloneProfile />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <CloneBio />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <CloneSocial />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourth">
                                                    <CloneInstances
                                                        cId={cId}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fifth">
                                                    <Integrations />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="sixth">
                                                    <Collaborators />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="seventh">
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="eighth">
                                                    <Notification />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="ninth">
                                                    <Messages />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tenth">
                                                    <ResponsePreferences />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Eleventh">
                                                    <Privacy />
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="twelve">
                                                    <BillingPlan />
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="thirteen">
                                                    <Usage
                                                        cId={cId}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourteen">
                                                    <Monetization />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Container >
                        </div >
                    </div >
                </div >

                <Footer />
            </>
    )
}

export default NewSetting
