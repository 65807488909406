import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from "query-string"
import { useEffect } from 'react';
import TrainingNav from './TrainingNav';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import TitleBar from '../../CommonComponents/TitleBar';

const SupportArticle = () => {
    let location = useLocation()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;
    const articleData = useSelector(state => state.help.article);

    const [filterArticle, setFilterArticle] = useState(false)

    useEffect(() => {
        if (articleData.length > 0) {
            setFilterArticle(articleData.filter(article => article.id === id)[0])
        }
    }, [articleData])

    return (
        <>
            <Header />
            <TitleBar title="Training" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="account-wrap large">
                        <Tab.Container id="left-tabs-example">
                            <div className="account-nav">
                                <TrainingNav
                                    article={articleData}
                                    articleId={id}
                                />
                            </div>
                            <div className="account-cont">
                                {/* <h5>Support/Account Management</h5> */}
                                <Tab.Content>
                                    {filterArticle ?
                                        filterArticle.items.map((item, index) => {
                                            return (
                                                item.id === +art_id ?
                                                    <React.Fragment key={index}>
                                                        <div className="account-block" key={index}>
                                                            <div className="training-block">
                                                                <div style={{ color: "#000" }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.content
                                                                    }}>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    : ''
                                            )
                                        })
                                        : ""}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )

}

export default SupportArticle;