import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeNotification, onChangeNotificationToggle, onSaveCloneProfile } from '../../../../Redux/Actions/CloneSettingAction';

const Notification = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [loader, setLoader] = useState({
        save: false,
    });
    const settingsData = useSelector(state => state.cloneSetting.data)

    const handleChangeEnable = (e) => {
        const { name, checked } = e.target
        if (!checked) {
            dispatch(onChangeNotification(name, ""))
        }
        dispatch(onChangeNotificationToggle(name, checked))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeNotification(name, value))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingsData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    return (
        <div>
            <div className="notifications_wrap ps-3">
                <form className="row" onSubmit={handleSubmit}>
                    <div className="col-12">
                        <div className="notification_head">
                            <h2>Notifications</h2>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 className='f-16'>Email Not Responded To By Clone </h3>
                                <p className="para_text2 f-13" style={{ fontWeight: "500" }}>If your clone is unable to respond to a user's inquiry, be cautious.</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name='sendEmail'
                                        checked={settingsData.notification.sendEmail.enable}
                                        onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>

                        </div>


                        {settingsData.notification.sendEmail.enable ?
                            <div className="opt-select mt-2">
                                <h3 className='f-16' style={{ width: '100%' }}>Enter Email</h3>
                                <input
                                    className='common-inp'
                                    type="email"
                                    name='sendEmail'
                                    placeholder='Enter email where you want to send response'
                                    value={settingsData.notification.sendEmail.email}
                                    onChange={handleChange}
                                    required
                                />

                            </div>
                            : ""
                        }

                    </div>
                    <div className="col-lg-7 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 className='f-16'>Clone Activity Summary</h3>
                                <p className="para_text2 f-13">Email report of your clone activity with user</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name='sendReport'
                                        checked={settingsData.notification.sendReport.enable}
                                        onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>


                        </div>
                        {settingsData.notification.sendReport.enable ?
                            <div className="opt-select mt-2">
                                <h3 className='f-16' style={{ width: '100%' }}>Enter Email</h3>
                                <input
                                    type="email"
                                    className='common-inp'
                                    name='sendReport'
                                    placeholder='Enter email where you want to send activity report'
                                    value={settingsData.notification.sendReport.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            : ""
                        }
                    </div>

                    <div className="col-7  text-end" style={{ marginTop: "50px" }}>
                        {+auth.user.isClientAccount === 1 ? null :
                            <button type='submit' className="theme-btn me-0"><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span>  </button>
                        }
                    </div>

                </form>
            </div>
        </div>
    )
}

export default Notification
