import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai';
import { SketchPicker } from 'react-color';
import { themeColor } from '../../../../Global/Global';
import rgbHex from "rgb-hex";
import { onUpdateInstance } from '../../../../Redux/Actions/CloneSettingAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const CreateInstancesModal = ({ type, curlElem, show, handleClose, animation, performHandleChange, performFunction, fetchInstances }) => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: "",
        animationName: "animate__shakeX",
        enable: false,
        color: themeColor,
        autoSubmit: false
    })
    const [loader, setLoader] = useState(false)

    const handleColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        setState({
            ...state,
            color: hex
        })
        if (type === "Create") {
            performHandleChange("color", hex)
        }
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (type === "Create") {
            if (name === "autoSubmit") {
                performHandleChange(name, checked)
            } else {
                performHandleChange(name, value)
            }
        }
        if (name === "autoSubmit") {
            setState({ ...state, [name]: checked })
        } else {
            setState({ ...state, [name]: value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (type === "Create") {
            performFunction();
        } else {
            let ebScript = `<script src="https://backend.aiduals.com/assets/clone_bot.js?id=${curlElem.cId}&uId=${auth.user.id}&name=${state.name}&an=${state.animationName}&at=${state.autoSubmit}&cl=${state.color?.replace("#", "")}" id="clone_bot"></script>`
            let data = {
                id: curlElem.id,
                cId: curlElem.cId,
                name: state.name,
                embedCode: ebScript,
                autoSubmit: state.autoSubmit
            }
            setLoader(true)
            dispatch(onUpdateInstance(data, setLoader, fetchInstances, handleClose))
        }
        setState({
            ...state,
            enable: false
        })
    }

    useEffect(() => {
        if (curlElem) {
            const paramsString = curlElem.embedCode.match(/\?.*\"/)[0].slice(1, -1);
            const params = new URLSearchParams(paramsString);
            const name = params.get('name');
            const an = params.get('an');
            const cl = params.get('cl').split("\"")[0];
            setState({
                ...state,
                animationName: an,
                color: `#${cl}`,
                name: name,
                autoSubmit: curlElem.autoSubmit === "0" ? false : true
            })
        }
    }, [curlElem])

    return (
        <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
            <Modal.Body className="p-0">
                <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                <div className="feed_warp p-3" style={{ marginTop: "10px" }}>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feed_mod_head">
                                        <h2 className='mb-3'>Create Instance</h2>
                                    </div>
                                    <div className="input-outer w-100">
                                        <label>Enter Name</label>
                                        <input
                                            type='text'
                                            value={state.name}
                                            name='name'
                                            onChange={handleChange}
                                            required
                                            placeholder='Enter Instance Name'
                                            className="common-inp alt"
                                        />

                                    </div>
                                    <div className="input-outer w-100 my-2">
                                        <label>Select Animation</label>
                                        <select
                                            type='text'
                                            value={state.animationName}
                                            name='animationName'
                                            onChange={handleChange}
                                            required
                                            className="common-inp alt"
                                        >
                                            <option value={""}>Select Animation</option>
                                            {animation.length > 0 ?
                                                animation.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.name}>{curElem.name.replace("animate__", "")}</option>
                                                    )
                                                })
                                                : ""}

                                        </select>

                                    </div>
                                    <div class="input-outer instance_modal_check w-100 mt-3">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <label>Auto submit after stop speaking</label>
                                            <input
                                                className="form-check-input"
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                }}
                                                type="checkbox"
                                                name='autoSubmit'
                                                checked={state.autoSubmit}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-outer w-100 mt-3">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <label>Select Color</label>
                                            <div
                                                className="colorValue"
                                                id="t-color-btn"
                                                style={{ background: state.color ? state.color : themeColor, cursor: "pointer" }}
                                                onClick={() => setState({
                                                    ...state,
                                                    enable: true
                                                })}
                                            ></div>
                                        </div>
                                        {state.enable ?
                                            <>
                                                <div className="swatch-cover"
                                                    onClick={() => setState({
                                                        ...state,
                                                        enable: false
                                                    })}
                                                />
                                                <SketchPicker
                                                    color={state.color}
                                                    onChange={(e) => handleColor(e)}
                                                    className="color-picker-style"
                                                />
                                            </>
                                            : null}
                                    </div>

                                    <div className="feed_btn text-end">
                                        <button type='submit' className="theme-btn text-p4 mt-3"><span>{type === "Create" ? type : loader ? <>Updating <i className='fa fa-spinner fa-spin' /></> : "Update"}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Modal.Body>
        </Modal >
    )
}

export default CreateInstancesModal
