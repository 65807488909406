import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { PiArrowElbowDownRight } from 'react-icons/pi'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { onExportUserCsv, onFetchMessage } from '../../../../Redux/Actions/CloneActions'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import { FaArrowCircleDown } from 'react-icons/fa'

const ConversationsAudience = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [type, SetType] = useState("")
    const [conversationData, setConversationData] = useState([])
    const { cId } = queryString.parse(location.search)
    const [loader, setLoader] = useState({
        fetch: false,
        submit: false
    })

    const handleExport = () => {
        if (conversationData.length > 0) {
            let data = {
                cId: cId,
                type: "chatMessage"
            }
            setLoader({
                ...loader,
                submit: true
            });
            dispatch(onExportUserCsv(data, loader, setLoader))
        }
        else {
            dispatch(setAlert("Please add content first to export it!", "danger"))
        }
    }

    const fetchConversation = () => {
        setLoader({
            ...loader,
            fetch: true
        });
        let data = {
            cId: cId
        }
        dispatch(onFetchMessage(data, setConversationData, loader, setLoader))
    }
    const handleSearch = (e) => {
        SetType(e.target.value)
    }
    useEffect(() => {
        fetchConversation()
    }, [])

    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2> Conversations </h2>
                        <p>See all conversations with your clone.</p>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='off-btn me-3'><span onClick={handleExport}><FaArrowCircleDown className='me-1' style={{ color: "#71717a" }} />{loader.submit ? <> Exporting <i className='fa fa-spinner fa-spin' /> </> : "Export"}</span></button>
                    </div>
                </div>
                <div className="train_content_search mt-5">
                    <div className="row">
                        <div className="col-xxl-2 col-xl-3 col-12">
                            <div style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Search conversations"
                                    value={type}
                                    onChange={handleSearch}
                                />
                                <span className="inpx-icon"><BiSearch style={{ color: "#a1a1aa", fontSize: "20px" }} /></span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>S.No</th>
                                    <th>Type</th>
                                    <th>Message</th>
                                    <th>Created</th>
                                </tr>
                                {conversationData.length > 0 && conversationData.filter((item) => {
                                    return item.type && item.type !== "system" && item.type.includes(type) && item.type !== "";
                                }).length > 0 ? (
                                    conversationData.filter((item) => {
                                        return item.type && item.type !== "system" && item.type.includes(type) && item.type !== "";
                                    }).map((curlElem, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{curlElem.type}</td>
                                                <td className='mess_tbl_row'>{curlElem.message}</td>
                                                <td>{curlElem.created}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={5} className='text-center py-5'>
                                            {loader.fetch ? <><i className='fa fa-spin fa-spinner fetch-loader' /></> : conversationData.length > 0 ? "No data found." : "No Conversations on this clone yet!"}
                                        </td>
                                    </tr>
                                )}



                            </table>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ConversationsAudience
