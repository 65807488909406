import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useLocation } from 'react-router-dom';
import { onAcceptImage } from '../../../../Redux/Actions/CloneSettingAction';
import queryString from 'query-string';

const AllCharacters = () => {

  const dispatch = useDispatch();
  const location = useLocation()
  const { cId } = queryString.parse(location.search)
  const [character, setCharacter] = useState([])
  const [loader, setLoader] = useState({
    fetch: false,
    accept: false
  })

  const [selectedCharacter, setSelectedCharacter] = useState("");
  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(-1);
  const [isChar, setIsChar] = useState(true)

  const fetchCharacter = () => {
    setLoader({ ...loader, fetch: true })
    let data = {}
    dispatch(fetchData("fetch-character", data, setCharacter, loader, setLoader))
  }
  const handleSelect = (ind, carUrl, active) => {
    setLoader({ ...loader, accept: true })
    let data = {
      id: cId,
      url: carUrl,
      enable: active
    }
    setSelectedCharacterIndex(ind)
    dispatch(onAcceptImage(data, setSelectedCharacter, loader, setLoader))
  }

  const handleChange = (e) => {
    const {checked } = e.target;
    if (!checked) {
      handleSelect(-1, "", false)
    }
    setIsChar(checked)
  }

  useEffect(() => {
    fetchCharacter();
  }, [])
  return (
    <>
      <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
        <div className="container-fluid" >
          <div className="row" style={{ padding: "0 20px" }}>
            <div className="col-12">
              <div className="train_content_head row ">
                <div className='d-flex align-items-xl-center align-items-lg-center align-items-baseline justify-content-between flex-xxl-row flex-xl-row flex-lg-row flex-column'>
                  <h2>Characters</h2>
                  <div className="opt-select">
                    <p className='mx-2'>
                      If you do not want any character, you should disable it.
                    </p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name='enable'
                        checked={isChar}
                        onChange={handleChange}
                      />
                      <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                    </label>
                  </div>
                </div>
                {/* <div className='col-xxl-4 col-lg-5 col-12 text-xxl-end text-center mt-xxl-0 mt-xl-3 mt-3'>
                  <button className='theme-btn me-3 text-p4 ' ><span>Extract Tone from Content</span></button>

                </div> */}
              </div>
              <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
            </div>
            <div className="Choose_template">
              {isChar ?
                <div className="row">
                  {character.length > 0 ?
                    character.map((curElem, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className='col-xl-3 col-lg-4 col-md-6 col-12' onClick={() => handleSelect(index, curElem.url, isChar)}>
                            <div className="character-img-single">
                              <img src={curElem.url} alt="" style={{ cursor: "pointer", width: "100%", height: "100%", objectFit: "contain", border: selectedCharacter === curElem.url ? "2px solid #ff6a27" : "" }} />
                              <div className='loader_import_center'>
                                {loader.accept && selectedCharacterIndex === index ? <i className='fa fa-spin fa-spinner fetch-loader' /> : ""}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                    :
                    <div className='text-center'>
                      {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                        <p>There is no character available.</p>
                      }
                    </div>
                  }
                </div>
                : ""
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllCharacters