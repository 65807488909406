import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Rebranding from "./Rebranding";
import TitleBar from "../../CommonComponents/TitleBar";
import wordpress from "../../../assets/images/round-icon-wordpress.png";
import download from "../../../assets/images/round-icon-download.png";
import importIcon from "../../../assets/images/round-icon-import.png";
import customize from "../../../assets/images/round-icon-customize.png";
import youtubeimg from "../../../assets/images/youtubeimg.jpg";
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { useSelector } from "react-redux";
import { appName } from "../../../Global/Global";

const Upgrade = () => {
    const auth = useSelector(state => state.auth)
    const [state, setState] = useState()
    const handleShow = () => setState(true)
    const handleClose = () => setState(false)
    const [memberShip, setMemberShip] = useState([])

    const [frameUrl, setFrameUrl] = useState();

    const addFrameUrl = () => {
        setFrameUrl("https://player.vimeo.com/video/514577280");
        handleShow()
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <>
            <Header />
            <TitleBar title="Upgrade" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="account-wrap">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="zero">
                            <div className="account-nav">
                                <Nav variant="f" className="flex-column">

                                    {memberShip.includes("enterprise") ||
                                        memberShip.includes("fastpass") ||
                                        memberShip.includes("bundle") ?
                                        <Nav.Item>
                                            <Nav.Link eventKey="eighth">Rebranding</Nav.Link>
                                        </Nav.Item>
                                        : null}
                                    <Nav.Item><Nav.Link eventKey="tab-13">Business Finder</Nav.Link></Nav.Item>

                                    <Nav.Item><Nav.Link eventKey="first">FB Templates</Nav.Link></Nav.Item>

                                    <Nav.Item><Nav.Link eventKey="third">Client Contract</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="fourth">Reseller License</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="sixth">DFY Lead Magnets</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="seventh">Agency Website</Nav.Link></Nav.Item>

                                    {/* {memberShip.includes("unlimited") ||
                                        memberShip.includes("fastpass") ||
                                        memberShip.includes("bundle") ?
                                        <Nav.Item><Nav.Link eventKey="nineth">Video Flix</Nav.Link></Nav.Item> : null} */}

                                    <Nav.Item><Nav.Link eventKey="tenth">DFY Course</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="eleventh">Web Hosting</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="thirteenth">DFY Articles</Nav.Link></Nav.Item>
                                </Nav>
                            </div>
                            <div className="account-cont">
                                <Tab.Content>
                                    <Tab.Pane eventKey="zero">
                                        <div className="account-block alt first">
                                            <div className="welcome-cont">
                                                <img alt="" src={require('../../../assets/images/upgrade.png')} />
                                                <h4 className="pt-5">Welcome!</h4>
                                                <p>Choose any option to upgrade</p>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey={"tab-13"}>
                                        <>
                                            <h3>Business Finder</h3>
                                            <iframe
                                                src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                                style={{ width: "100%", height: "100vh" }}
                                                title="business-finder"
                                            />
                                        </>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="first">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">FB Templates</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3>Facebook Ads Templates</h3>
                                                        <div className="upgrade-para">
                                                            <p className="text-center ">
                                                                Download this done-for-you Facebook and Instagram ad
                                                                creatives and ad copy templates that can easily be
                                                                customized to be used for any niche
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="tabepaneIn-cont mt-4">
                                                        <h3>Download your Facebook Ads Templates here</h3>
                                                        <a
                                                            rel="noreferrer"
                                                            href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="theme-btn full btn-block mt-0">
                                                                <span>  Download the Templates</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="account-block alt">VIP Event Calendar</div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">Client Contract</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3>Client Contract</h3>
                                                        <div className="upgrade-para">
                                                            <p className="text-center ">
                                                                Download our "Client Contract" template. This is a docx
                                                                file, works best with MS Word. Edit the doc and add your
                                                                business name, address and other details. Fill the empty
                                                                blank space with your service, eg Video Marketing, Local
                                                                Reputation Management, Social Media Marketing, Design
                                                                &amp; Creatives, Ads Management and so on.
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="tabepaneIn-cont-alt">
                                                        <div className="upgrade-content">
                                                            <div className="row">
                                                                <div className="col-xl-4 col-md-6 col-sm-12 col-md-6 mb-2">
                                                                    <div className="upgrade-box-content text-center  text-white">
                                                                        <div className="upgrade-heading">
                                                                            <h6>Brief Pitch Template</h6>
                                                                        </div>
                                                                        <div className="upgrade-para p-3">
                                                                            <a
                                                                                rel="noreferrer"
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="theme-btn full btn-block mt-0">
                                                                                    <span> Download</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 col-sm-12  mb-2">
                                                                    <div className="upgrade-box-content text-center  text-white">
                                                                        <div className="upgrade-heading">
                                                                            <h6>Service Agreement</h6>
                                                                        </div>
                                                                        <div className="upgrade-para p-3">
                                                                            <a
                                                                                rel="noreferrer"
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="theme-btn full btn-block mt-0">
                                                                                    <span> Download</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 col-sm-12  mb-2">
                                                                    <div className="upgrade-box-content text-center  text-white">
                                                                        <div className="upgrade-heading">
                                                                            <h6>Meeting Setup Template</h6>
                                                                        </div>
                                                                        <div className="upgrade-para p-3">
                                                                            <a
                                                                                rel="noreferrer"
                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                                                                                target="_blank"
                                                                            >
                                                                                <button className="theme-btn full btn-block mt-0">
                                                                                    <span> Download</span>
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">Reseller License</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3>Reseller License</h3>
                                                        <div className="upgrade-para">
                                                            <p className="text-center ">
                                                                Go to JVZoo Dashboard and request your affiliate link.
                                                                Once you've requested, submit a ticket with
                                                                your {appName} Business transaction ID, affiliate ID and
                                                                email address for our team to activate your reseller
                                                                license. Please allow upto 2 working days.
                                                            </p>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className=" text-center  text-white">
                                                                    <div className="">
                                                                        <a
                                                                            rel="noreferrer"
                                                                            href="https://www.jvzoo.com/affiliate/affiliateinfonew/index/407805"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0">
                                                                                <span>Request Affiliate Link</span>
                                                                            </button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className=" text-center  text-white">
                                                                    <div className="">
                                                                        <a
                                                                            rel="noreferrer"
                                                                            href="https://support.vineasx.com/"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0">
                                                                                <span>Raise Support Ticket</span>
                                                                            </button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <div className="account-block alt">Video Commercial</div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sixth">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">DFY Lead Magnets</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3 className="text-white">DFY Lead Magnets</h3>
                                                        <a
                                                            rel="noreferrer"
                                                            href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                                                            target="_blank"
                                                        >
                                                            <button className="theme-btn   btn-block mt-0" type="button">
                                                                <span>Download</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="seventh">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">Agency</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3>Agency</h3>
                                                        <div className="row">
                                                            <div className="col-md-12 mx-auto">
                                                                <div className="upgrade-box-content p-2 text-white  ">
                                                                    <div className="row">
                                                                        <div className="col-md-2 pr-0 text-center ">
                                                                            <img src={wordpress} alt="wprdpress" />
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <div className="upgrade-para">
                                                                                <p className="m-0">
                                                                                    Fresh Install and configure wordpress at your
                                                                                    server. If you need help in installing and
                                                                                    configuring wordpress, Click here to find the
                                                                                    tutorial.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                                    <div className="row">
                                                                        <div className="col-md-2 pr-0 text-center ">
                                                                            <img src={download} alt="download" />
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <div className="upgrade-para">
                                                                                <p className="m-0">
                                                                                    Once wordpress is installed at your server.
                                                                                    Download the site content by clicking the
                                                                                    "Download site button". This will download a
                                                                                    wpress file which you will be needing to
                                                                                    import in your wordpress site.
                                                                                </p>
                                                                                <div className="row  d-flex justify-content-center">
                                                                                    <div className="col-md-12">
                                                                                        <div className="upgrade-box-content text-center  text-white" style={{ border: "none" }}>
                                                                                            <div className="upgrade-para p-3">
                                                                                                <a
                                                                                                    rel="noreferrer"
                                                                                                    href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                                                                                                    target="_blank"
                                                                                                >
                                                                                                    <button className="theme-btn btn-block mt-0">
                                                                                                        <span>Download the site</span>
                                                                                                    </button>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                                    <div className="row">
                                                                        <div className="col-md-2 pr-0 text-center ">
                                                                            <img src={importIcon} alt="import" />
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <div className="upgrade-para">
                                                                                <p className="m-0">
                                                                                    Import the xml file in to your wordpress site.
                                                                                    If you need help importing the xml file in
                                                                                    wordpress, Click here to find the tutorial.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="upgrade-box-content mt-3 p-2 text-white  ">
                                                                    <div className="row">
                                                                        <div className="col-md-2 pr-0 text-center ">
                                                                            <img src={customize} alt="customize" />
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <div className="upgrade-para">
                                                                                <p className="m-0">
                                                                                    Customize the site as according to your need.
                                                                                    If you need help customizing the site. Click
                                                                                    here to find the tutorial
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tabepaneIn-cont mt-4">
                                                        <h3>Tutorial</h3>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="upload-box-content">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="tutorial-content">
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center">
                                                                                        <img src={wordpress} alt="wordpress" />
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="upgrade ml-lg-4">
                                                                                            <a
                                                                                                rel="noreferrer"
                                                                                                className="btn-block"
                                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                                                                                                target="_blank"
                                                                                            >
                                                                                                <button
                                                                                                    title="Install WordPress From cPanel"
                                                                                                    className="theme-btn btn-block mt-0"
                                                                                                >
                                                                                                    <span>Install WordPress From cPanel</span>
                                                                                                </button>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-4">
                                                                                    <div className="col-md-2 text-center">
                                                                                        <img src={importIcon} alt="" />
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="upgrade ml-lg-4">
                                                                                            <a
                                                                                                rel="noreferrer"
                                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                                                                                                target="_blank"
                                                                                            >
                                                                                                <button className="theme-btn btn-block mt-0">
                                                                                                    <span>Import Website </span>
                                                                                                </button>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-4">
                                                                                    <div className="col-md-2 text-center">
                                                                                        <img src={customize} alt="" />
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="upgrade ml-lg-4">
                                                                                            <a
                                                                                                rel="noreferrer"
                                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                                                                                                target="_blank"
                                                                                            >
                                                                                                <button className="theme-btn btn-block mt-0">
                                                                                                    <span>Customize website</span>
                                                                                                </button>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-4">
                                                                                    <div className="col-md-2 text-center">
                                                                                        <img src={download} alt="" />
                                                                                    </div>
                                                                                    <div className="col-md-10">
                                                                                        <div className="upgrade ml-lg-4">
                                                                                            <a
                                                                                                rel="noreferrer"
                                                                                                href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                                                                                                target="_blank"
                                                                                            >
                                                                                                <button
                                                                                                    className="theme-btn btn-block mt-0"
                                                                                                    title="Upload Video and Usage in Website"
                                                                                                >
                                                                                                    <span>Upload Video and Usage in Website</span>
                                                                                                </button>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tabepaneIn-cont mt-4">
                                                        <h3>How to Install the Agency Website</h3>
                                                        <div className="row justify-content-center">
                                                            <div className="col-md-12">
                                                                <div className="card fb-card border-0">
                                                                    <div
                                                                        className="card-header text-center"
                                                                        style={{ background: "rgb(0, 123, 255)" }}
                                                                    >
                                                                        <h6 className="text-white Account-Management-headings">
                                                                            How to Install the Agency Website
                                                                        </h6>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <img
                                                                            src={youtubeimg}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                        <span
                                                                            className="youtubeicon"
                                                                            onClick={(e) => addFrameUrl()}
                                                                            style={{ cursor: "pointer", color: "#007bff" }}
                                                                        >
                                                                            <i className="fas fa-play-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                    <div></div>
                                                                </div>


                                                                <Modal className={`theme-modal upgrade_modal video-modal xl`} show={state} onHide={handleClose} centered>
                                                                    <Modal.Body>
                                                                        <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{ fontSize: '25px', cursor: "pointer" }} /></div>
                                                                        <div className="ratio ratio-16x9">
                                                                            <iframe class="embed-responsive-item" src={frameUrl} width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AiSocialsWalkthrough"></iframe>
                                                                        </div>
                                                                    </Modal.Body>
                                                                </Modal>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="eighth">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">Rebranding</h2>
                                            <Rebranding />
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="nineth">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">Video Flix</h2>
                                            <div className="account-block alt">
                                                <div className="upgrade-content">
                                                    <div className="tabepaneIn-main">
                                                        <div className="row">
                                                            <div className="col-md-8 mx-auto">
                                                                <div className="upgrade-box-content  text-white  ">
                                                                    <div className="upgrade-heading">
                                                                        <h6>VideoFLIX - Netflix Style Video Site Builder</h6>
                                                                    </div>
                                                                    <div className="upgrade-para">
                                                                        <p className="text-center " style={{ fontSize: "16px" }}>
                                                                            VideoFLIX is a subscription-based video content
                                                                            management app built on cloud. Using VideoFLIX you
                                                                            will be able to create your own video content sharing
                                                                            website similar to NetFlix, HBO-Go, DisneyPLUS etc;
                                                                            sharing your own or others unique video content as
                                                                            channels or series. You will be able to create free,
                                                                            one-time or monthly payment plans for your customers
                                                                            to access your video content and build your video
                                                                            content subscription business!
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row  mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        rel="noreferrer"
                                                                        href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix_2.2.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn">
                                                                            <span>
                                                                                Download VideoFlix
                                                                            </span>
                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        rel="noreferrer"
                                                                        href="https://abhibonus.s3-us-west-2.amazonaws.com/VideoFlix/VideoFlix+Training+Docs+updated.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn">
                                                                            <span>
                                                                                Download Tutorial Docs
                                                                            </span>

                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tenth">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">DFY Courses</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="upgrade-content">
                                                        <div className="row">
                                                            <div className="col-md-8 mx-auto">
                                                                <div className="upgrade-box-content  text-white  ">
                                                                    <div className="upgrade-heading text-center">
                                                                        <h6 className="m-0">DFY Courses</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 1 </span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 11 </span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 2</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 12</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 3</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 13</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 4</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 14</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 5</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 15</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 6</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 16</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                                                        target="_blank"
                                                                    >
                                                                        {" "}
                                                                        <button className="theme-btn"> <span> Download DFY Course 7</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip"
                                                                        target="_blank"
                                                                    >
                                                                        {" "}
                                                                        <button className="theme-btn"> <span> Download DFY Course 17</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip"
                                                                        target="_blank"
                                                                    >
                                                                        {" "}
                                                                        <button className="theme-btn"> <span> Download DFY Course 8</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 18</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 9</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 19</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 10</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="upgrade-box-content text-center p-3 text-white">
                                                                    <a
                                                                        href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip"
                                                                        target="_blank"
                                                                    >
                                                                        <button className="theme-btn"> <span> Download DFY Course 20</span></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="eleventh">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">Web Hosting</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3>Web Hosting</h3>
                                                        <div className="upgrade-para">
                                                            <p className="text-center ">
                                                                Please raise a support ticket with
                                                                your {appName} purchase
                                                                transaction ID, your domain name that you
                                                                currently own and wish to use with the hosting, email
                                                                address, your full name and a note about how you would
                                                                be using the webhosting for your business.
                                                            </p>
                                                        </div>
                                                        <a
                                                            rel="noreferrer"
                                                            href="https://support.vineasx.com/"
                                                            target="_blank"
                                                        >
                                                            <button className="theme-btn btn-block">
                                                                <span>Raise a Ticket</span>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="twelveth">
                                        <div className="account-block alt">Social Media Assets</div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="thirteenth">
                                        <div className="tabepaneIn">
                                            <h2 className="tabTitle">DFY Articles</h2>
                                            <div className="account-block alt">
                                                <div className="tabepaneIn-main">
                                                    <div className="tabepaneIn-cont">
                                                        <h3>DFY Articles</h3>

                                                        <div className="row  d-flex justify-content-center  mt-5">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack1.zip"
                                                                            target="_blank"
                                                                        >
                                                                            {" "}
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 1 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack6.zip"
                                                                            target="_blank"
                                                                        >
                                                                            {" "}
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 6 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="row  d-flex justify-content-center  mt-5">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 2 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack7.zip"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 7 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row  d-flex justify-content-center  mt-5">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack2.zip"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 3 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 8 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row  d-flex justify-content-center  mt-5">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack4.zip"
                                                                            target="_blank"
                                                                        >
                                                                            {" "}
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 4</span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack8.zip"
                                                                            target="_blank"
                                                                        >
                                                                            {" "}
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 8 </span> </button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row  d-flex justify-content-center  mt-5">
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack5.zip"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 5 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="upgrade-box-content text-center  text-white">
                                                                    <div className="upgrade-para p-3">
                                                                        <a
                                                                            href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/1_mil_arti/pack10.zip"
                                                                            target="_blank"
                                                                        >
                                                                            <button className="theme-btn btn-block mt-0"> <span> Article Pack 10 </span></button>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Upgrade;