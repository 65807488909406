import React, { useState } from 'react'
import playlist from "../../../../../assets/images/playlist.png"
import video from "../../../../../assets/images/video.png"
import channel from "../../../../../assets/images/channel.png"
import { AiOutlineClose } from 'react-icons/ai'
import { BsArrowsFullscreen, BsBoxArrowUpRight } from "react-icons/bs";
import { HiMicrophone } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'
import { onContinueData } from '../../../../../Redux/Actions/CloneActions'

export const YouTubeModal = (props) => {

    const dispatch = useDispatch()
    const [url, setUrl] = useState("")
    const [loader, setLoader] = useState({
        continue: false
    })

    const handleContinue = () => {
        let isValid = false, vId = false
        if (url != undefined || url != '') {
            let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            let match = url.match(regExp);
            if (match && match[2].length == 11) {
                isValid = true
            }
        }

        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        vId =  (match && match[7].length == 11) ? match[7] : false;

        if (url && isValid && vId) {
            let data = {
                cId: props.cId,
                url: url,
                videoId: vId,
                sId: props.sId,
                sourceId: 2,
                source: "Youtube",
                sourceType: ""
            }
            setLoader({ ...loader, continue: true })
            dispatch(onContinueData("youtube-create", data, props.fetchContent, props.handleClose, loader, setLoader))
        } else {
            dispatch(setAlert("Please enter youtube video link to continue!", "danger"))
        }
    }


    return (
        <>
            <div className="content_modal">
                <div className='d-flex justify-content-between align-items-center mt-3' >
                    <h2 className=''>Add YouTube Videos</h2>
                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-3 mt-3">
                            {/* <div className="website_pop">
                                <div className='website_upload' style={{ position: "relative" }}>
                                    <img src={video} alt="" />
                                    <div class="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                    </div>
                                </div>
                                <div style={{ width: "165px" }}>
                                    <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px", textAlign: "center" }}>Upload Video</p>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="col-3 mt-3">
                            <div className="website_pop">
                                <div className='website_upload' style={{ position: "relative" }}>
                                    <img src={playlist} alt="" />
                                    <div class="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                    </div>
                                </div>
                                <div style={{ width: "165px" }}>
                                    <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px", textAlign: "center" }}>Upload Channel</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-3 mt-3">
                            <div className="website_pop">
                                <div className='website_upload' style={{ position: "relative" }}>
                                    <img src={channel} alt="" />
                                    <div class="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                    </div>
                                </div>
                                <div style={{ width: "165px" }}>
                                    <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px", textAlign: "center" }}>Upload Playlist</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-12 mt-4">
                            <label htmlFor="" style={{ fontWeight: "600", marginBottom: "10px" }}>Paste YouTube Video Link</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    placeholder="https://www.youtube.com"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </div>
                        </div>
                        {/* 
                        <div className="col-12 mt-4">
                            <label htmlFor="" style={{ fontWeight: "600", marginBottom: "10px" }}>Is this content created by or about you?</label>
                            <div className="d-flex">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check ms-3">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className='d-flex align-items-center'>
                                <HiMicrophone className='me-3' fontSize={18} style={{ color: "#52525b", width: "20px", height: "20px" }} />
                                <div style={{ width: "100%" }}>
                                    <p className='text-p3'>Enable Speaker Identification</p>
                                    <div className='d-flex justify-content-between align-items-center w-100'>

                                        <p className='text-p3' style={{ color: '#a1a1aa' }}>
                                            <span style={{ color: '#ff6a27', marginRight: "3px" }}>Upload a voice sample here</span>
                                            to only extract segments where you are speaking.</p>
                                        <div className="opt-select">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                // checked={selectedSlide.faceNarration.enable}
                                                // onChange={handleChangeEnable}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                                <BsArrowsFullscreen className='me-3' fontSize={18} style={{ color: "#52525b", width: "20px", height: "20px" }} />
                                <div style={{ width: "100%" }}>
                                    <p className='text-p3'>Extract Questions</p>

                                    <div className='d-flex justify-content-between align-items-center w-100'>

                                        <p className='text-p3' style={{ color: '#a1a1aa' }}>Extract questions for people to ask when they speak to your clone.
                                            <span style={{ color: '#ff6a27' }}>Manage questions here <BsBoxArrowUpRight /></span></p>
                                        <div className="opt-select">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                // checked={selectedSlide.faceNarration.enable}
                                                // onChange={handleChangeEnable}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                                <BsArrowsFullscreen className='me-3' fontSize={18} style={{ color: "#52525b", width: "20px", height: "20px" }} />
                                <div style={{ width: "100%" }}>
                                    <p className='text-p3'>Analyze Tone</p>
                                    <div className='d-flex justify-content-between align-items-center w-100'>

                                        <p className='text-p3' style={{ color: '#a1a1aa' }}>Analyze your conversational style so your clone can speak like you.
                                            <span style={{ color: '#ff6a27' }} >Manage tone here <BsBoxArrowUpRight /></span></p>
                                        <div className="opt-select">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                // checked={selectedSlide.faceNarration.enable}
                                                // onChange={handleChangeEnable}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
            <div className="col-12 text-end mt-4">
                <button className='theme-btn me-3 text-p4' onClick={handleContinue}><span>Continue {loader.continue ? <i className='fa fa-spin fa-spinner' /> : ""}</span></button>
            </div>
        </>
    )
}
