import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { HiDotsHorizontal } from "react-icons/hi"
import { MdOutlineDownloadForOffline, MdFileCopy } from "react-icons/md"
import { usePDF } from '@react-pdf/renderer';
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import userImage from '../../../assets/images/dummyImage.png'
import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";

let interval = false, typingIndex = 0
let music = false;
const MessageBox = ({ curElem, index, length, activeBlink, setActiveBlink, typingActive, setTypingActive, showIntTyping, setShowIntTyping, cloneDetails }) => {
    const [date, setDate] = useState(new Date())
    let arr = ["reading", "thinking", "analysing", "researching", "checking on internet", "typing"]
    const [text, setText] = useState("")
    const [typingInterval, setTypingInterval] = useState(false)
    const [resMes, setResMes] = useState(arr[0])
    const [selectedMsg, setSelectedMsg] = useState(false)
    const [pdf, setPdf] = useState(false)
    const [instance, updateInstance] = usePDF({
        document: pdf
    })

    const handlePlayAudio = () => {
        if (music) {
            music.pause()
        }
        music = new Audio(curElem.ttsUrl?.file_url)
        music.play()
    }

    const handleGeneratePDF = (text) => {
        const styles = StyleSheet.create({
            section: {
                padding: 5,
                flexGrow: 1,
                fontSize: 10,
                border: "1px solid black"
            },
            footer: {
                fontSize: 11,
                position: "absolute",
                bottom: 11,
                right: 12,
            }
        })
        let data = <Document>
            <Page style={{ padding: 10 }}>
                <View style={styles.section}>
                    <View style={{ marginBottom: "10px", paddingLeft: "5px" }}>
                        <Text>{text}</Text>
                    </View>
                    <View style={styles.footer}>
                        <Text>This PDF is generated by AiDuals.</Text>
                    </View>
                </View>
            </Page>
        </Document>
        setPdf(data)
    }


    const handleAction = (type, ind) => {
        let copyText = document.getElementById(`message${index}`)
        if (type === "copy") {
            let p = document.createElement("textarea")
            p.value = copyText.firstChild.innerText
            p.select();
            p.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(p.value);
            p.remove()
        } else if (type === "doc") {
            let fileDownload = document.createElement("a");
            fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(copyText.firstChild.innerText)
            fileDownload.download = `conversation.doc`;
            fileDownload.click();
        } else {
            handleGeneratePDF(copyText.firstChild.innerText)
            setSelectedMsg(ind)
        }
    }


    useEffect(() => {
        if (pdf && (index === selectedMsg)) {
            updateInstance(pdf)
            setPdf(false)
        }
    }, [pdf])

    useEffect(() => {
        if (instance.url && (index === selectedMsg)) {
            let a = document.createElement('a')
            a.href = instance.url
            a.download = "conversation123123.pdf"
            a.click()
            setSelectedMsg(false)
        }
    }, [instance])


    useEffect(() => {

        if (typingActive && (index === length - 1)) {
            let scrollDiv = document.getElementById("chat-data-box")
            if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
            }
            setTypingInterval(
                setInterval(() => {
                    setResMes(arr[typingIndex])
                    typingIndex++
                    if (typingIndex === 6) {
                        typingIndex = 0
                    }
                }, 5000)
            )
        } else {
            typingIndex = 0
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

    }, [typingActive])

    useEffect(() => {
        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }
    }, [])

    useEffect(() => {
        if (curElem.type === "assistant" && activeBlink) {
            let arr = new Array(curElem.message)
            let i = 0
            let finalText = ""
            if (length - 1 === index) {
                if (music) {
                    music.pause()
                }
                if (curElem.ttsUrl?.file_url) {
                    music = new Audio(curElem.ttsUrl?.file_url)
                    music.play()
                }

                interval = setInterval(() => {
                    finalText += arr[0][i]
                    setText(finalText)
                    i++
                    let scrollDiv = document.getElementById("chat-data-box")
                    if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                        scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                    }
                    if (i >= curElem.message.length) {
                        setActiveBlink(false)
                        clearInterval(interval)
                        interval = false
                    }
                }, 20)
            }
            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem, activeBlink])

    return (
        curElem.type === "user" ?
            <>
                <div className="chat-single bot" >

                    <div className="chat-txt">
                        <div className="d-flex justify-content-end mb-2">
                            <div className="text-start me-2 ">
                                <h5 style={{ color: "#000", fontSize: "14px", fontWeight: "600" }}>Guest</h5>
                                <p style={{ fontSize: "13px", color: "#000", fontWeight: "500", padding: "0px" }}>{curElem.created || date.toISOString().replace("T", " ").split(".")[0]}</p>

                            </div>
                            <div className="chat-name"><img src={userImage} alt="" /></div>

                        </div>
                        <div className="chat-txt-in" style={{ background: "#fff", borderColor: "#ff6a27", }}>
                            <ContentEditable
                                html={curElem.message.replaceAll('\n', '<br />')}
                                disabled={true}
                                tagName='p'
                            />
                        </div>


                    </div>

                </div>
                <div className='main_typing_wrap'>
                    {typingActive && (length - 1 === index) ?
                        <div style={{ color: "#878381", display: "flex", marginBottom: "2px", paddingBottom: 5, fontSize: "14px" }}>
                            {showIntTyping ?
                                <span >Accessing Internet</span>
                                :
                                <> <span className="text-capitalize mx-1">{cloneDetails.name} </span>is {resMes}</>
                            }

                            <div className="typing">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div> : ""}
                </div>
            </>
            :
            <div className="chat-single " >

                <div className="chat-txt" >
                    <div className="d-flex mb-2">
                        <div className="bot-name"><img src={cloneDetails.image} /></div>
                        <div className="text-start ms-2">
                            <h5 style={{ color: "#000", fontSize: "14px", fontWeight: "600" }}>{cloneDetails.name}</h5>
                            <p style={{ fontSize: "13px", color: "#000", fontWeight: "500", padding: "0px" }}>{curElem.created || date.toISOString().replace("T", " ").split(".")[0]}</p>
                        </div>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className="chat-txt-in" id={`message${index}`} >
                            <ContentEditable
                                html={length - 1 === index && activeBlink ? text?.replaceAll('\n', '<br />')?.replaceAll("<", "\u2039")?.replaceAll(">", "\u203A").replaceAll("‹br /›", "<br />").replaceAll("```", "") : curElem.message.replaceAll('\n', '<br />').replaceAll("<", "\u2039").replaceAll(">", "\u203A").replaceAll("‹br /›", "<br />").replaceAll("```", "")}
                                disabled={true}
                                tagName='p'
                                style={{ color: "#fff" }}
                            />
                            <div className='text-end'>
                                {curElem.ttsUrl?.file_url ?
                                    <HiSpeakerWave style={{ color: "#fff", fontSize: "19px", cursor: "pointer" }} onClick={handlePlayAudio} />
                                    : ""
                                }

                            </div>
                            <div className="dropdown" style={{ position: "absolute", top: "0px", right: "17px" }}>
                                <div className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <HiDotsHorizontal style={{ fontSize: "25px", color: "#fff" }} />
                                </div>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <span
                                        className="dropdown-item d-flex  cursor-pointer"
                                        onClick={() => handleAction("copy")}
                                        style={{ fontSize: "14px", color: "#ff6a27", fontWeight: "600", cursor: "pointer" }}
                                    >
                                        <MdFileCopy className='me-1' size={20} />  COPY
                                    </span>
                                    <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => handleAction("doc")}
                                        style={{ fontSize: "14px", color: "#ff6a27", fontWeight: "600", cursor: "pointer" }}
                                    >
                                        <MdOutlineDownloadForOffline className='mr-1' size={20} />   DOC
                                    </span>
                                    <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => handleAction("pdf", index)}
                                        style={{ fontSize: "14px", color: "#ff6a27", fontWeight: "600", cursor: "pointer" }}
                                    >

                                        <MdOutlineDownloadForOffline className='mr-1' size={20} />   PDF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {pdf && (
                    <div>
                        {/* <PDFViewer width="100%" height="500px">
                            {pdf}
                        </PDFViewer> */}

                    </div>
                )}

            </div>

    )
}

export default MessageBox