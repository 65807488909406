import React, { useState } from 'react'
import SweetAlert from '../../../CommonComponents/SweetAlert'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { FaTrash } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import CreateProductModal from './CreateProductModal'
import { useSelector } from 'react-redux'

const ProductRows = ({ index, cId, curElem, productData, setProductData, fetchProducts }) => {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [sweet, setSweet] = useState({
    enable: false,
    confirmButtonName: "Delete",
    loader: false
  })

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const onDelete = () => {
    setSweet({
      ...sweet,
      enable: true,
    })
  }

  const onCancelDelete = () => {
    setSweet({
      ...sweet,
      enable: false,
      confirmButtonName: "Delete",
      loader: false
    })
  }

  const performDelete = () => {
    let data = {
      id: curElem.id,
      cId: curElem.cId,
      sId: curElem.sId

    }
    setSweet({
      ...sweet,
      confirmButtonName: "Deleting",
      loader: true
    })
    dispatch(onDeleteCampaign("delete-product", data, productData, setProductData, setSweet))
  }


  return (
    <tr>
      <td>{index + 1}</td>
      <td><img src={curElem.image} style={{ height: "50px" }} alt="no Img" /></td>
      <td>{curElem.name}</td>
      <td>{curElem.description}</td>
      <td>
        {+auth.user.isClientAccount === 1 ? null :
          <>
            <span className='ms-2' onClick={handleShow}>
              <MdEdit title='Edit' style={{ color: "#616161", cursor: "pointer" }} />
            </span>
            <span className='ms-2' onClick={onDelete}>
              <FaTrash title='Delete' style={{ color: "#616161", cursor: "pointer" }} />
            </span>
          </>
        }
      </td>

      <SweetAlert
        show={sweet.enable}
        message={"<b>Are you sure?</b> <br> <br> You want to delete this product?"}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        alertLoader={sweet.loader}
        cancelButtonName={"Cancel"}
        handleClose={onCancelDelete}
        performDelete={performDelete}
      />


      <CreateProductModal
        type="update"
        curElem={curElem}
        show={show}
        handleClose={handleClose}
        cId={cId}
        fetchProducts={fetchProducts}
      />
    </tr>
  )
}

export default ProductRows

