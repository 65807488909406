import React, { useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { VscClose } from "react-icons/vsc";
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { useDispatch } from 'react-redux';
import { onCreateURl } from '../../../../Redux/Actions/CloneActions';
import { setAlert } from '../../../../Redux/Actions/AlertActions';
import { useSelector } from 'react-redux';
const SocialUrl = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const { cId } = queryString.parse(location.search)
    const [loader, setLoader] = useState({
        fetch: true,
        submit: false
    })
    const [url, setUrl] = useState()
    const [social, setSocial] = useState(false)

    const handleAdd = () => {
        setSocial(!social)
    }

    const validateUrlChecker = (urlType) => {
        if (urlType === "Instagram") {
            const instagramUrlPattern = /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._%+-]+\/?$/;
            return instagramUrlPattern.test(url);
        } else if (urlType === "Facebook") {
            const facebookUrlPattern = /^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9._%+-]+\/?$/;
            return facebookUrlPattern.test(url);
        } else if (urlType === "Twitter") {
            const twitterUrlPattern = /^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)\/[A-Za-z0-9_]+\/?$/;
            return twitterUrlPattern.test(url);
        } else if (urlType === "Youtube") {
            const youtubeUrlPattern = /^(https?:\/\/)(www\.)?(youtube\.com|youtu\.?be)\/.+$/i;
            return youtubeUrlPattern.test(url);
        } else if (urlType === "LinkedIn") {
            const linkedInUrlPattern = /^(https?:\/\/)?(www\.)?linkedin\.com/;
            return linkedInUrlPattern.test(url);
        } else {
            const websiteRegex = /^(https?:\/\/)(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/i;
            return websiteRegex.test(url);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let length = props.contentData.filter(val => val.socialType.toLowerCase() === props.name.toLowerCase()).length
        if (length > 0) {
            dispatch(setAlert(`${props.name} is already added!`, "danger"))
            return;
        }
        if (!validateUrlChecker(props.name)) {
            dispatch(setAlert(`Please enter valid ${props.name.toLowerCase()} URL to continue!`, "danger"))
            return;
        }

        let data = {
            cId: cId,
            url: url,
            socialType: props.name
        }
        setLoader({
            ...loader,
            submit: true
        });
        dispatch(onCreateURl(data, setSocial, props.fetchContent, loader, setLoader, setUrl))
    }
    const handleChange = (e) => {
        setUrl(e.target.value)
    }

    return (
        <>
            <div className='band_wrapper mt-2'>
                <div className="row align-items-center text-lg-start text-md-start text-center">
                    <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                        <div className="band_img_wrap m-lg-0 m-md-0 m-auto">
                            <img src={props.logo} alt="insta Logo" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-md-7 col-12 mt-md-0 mt-1 mb-md-0 mb-1">
                        <div className="details">
                            <h3 className="name mt-md-0 mt-1 mb-md-0 mb-1">{props.name}</h3>
                            <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        {+auth.user.isClientAccount === 1 ? null :
                            <button style={{ fontWeight: "500", fontSize: "13px" }} onClick={handleAdd} > {!social ? <> Add <IoAddOutline className='ps-1 addIcon' /> </> : <> Close <VscClose className='ps-1 addIcon' /> </>}</button>
                        }
                    </div>
                </div>
                {
                    social ?
                        <form onSubmit={handleSubmit}>
                            <div className="row mt-4 align-items-center text-lg-start text-md-start text-center w-100">
                                <div className="col-lg-9 col-md-8 col-12 ">
                                    <div className="setting_input d-flex">
                                        <div className="input-outer w-100">
                                            <input
                                                className="common-inp alt"
                                                type="url"
                                                placeholder="Enter Your URL"
                                                onChange={handleChange}
                                                value={url}
                                                name='url'
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12 mt-md-0 mt-3">
                                    <button className="theme-btn " style={{ fontWeight: "500", fontSize: "13px", width: "auto" }} type='submit'> <span className='d-flex align-items-center justify-content-center '>{loader.submit ? <> Submitting <i className='fa fa-spinner fa-spin ms-2' /> </> : "Submit"}</span>    </button>
                                </div>

                            </div>
                        </form>

                        : ""

                }
            </div >


        </>

    )
}

export default SocialUrl
