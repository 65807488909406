import React, { useEffect, useState } from 'react'
import instaLogo from '../../../../../src/assets/images/SocialIcons/instagramLogo.png';
import facebook from '../../../../../src/assets/images/SocialIcons/facebook.png';
import twitter from '../../../../../src/assets/images/SocialIcons/twitter.png';
import website from '../../../../../src/assets/images/SocialIcons/wide.png';
import youtube from '../../../../../src/assets/images/SocialIcons/youtube.png';
import LinkedIn from '../../../../../src/assets/images/SocialIcons/linkedin.png';
import SocialUrl from './SocialUrl';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { onDeleteSocial, onFetchAllUrl } from '../../../../Redux/Actions/CloneActions';
import SweetAlert from '../../../CommonComponents/SweetAlert';
import { useSelector } from 'react-redux';

const CloneSocial = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const { cId } = queryString.parse(location.search)
    const [loader, setLoader] = useState({
        fetch: false,
        submit: false
    })
    const [contentData, setContentData] = useState([])
    const fetchContent = () => {
        let data = {
            cId: cId
        }
        dispatch(onFetchAllUrl(data, setContentData, loader, setLoader))
    }

    useEffect(() => {
        fetchContent()
    }, [])

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }
    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const SocialDelete = () => {
        let data = {
            id: sweet.id,
            cId: cId,

        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteSocial(data, contentData, setContentData, setSweet, loader, setLoader))
    }


    return (
        <div className='social_wrap'>
            <div className='row'>
                <div className=" col-xl-8 col-lg-12">
                    <h2>Clone Social</h2>
                    <p className='f-13 para_text2'>Put this on your profile. has no impact on training.</p>
                    <div className="head d-flex justify-content-between mt-3">
                        <p className='f-13' style={{ color: "#52525b", fontWeight: "bold" }}>All Social</p>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-12">
                    <SocialUrl
                        name="Instagram"
                        logo={instaLogo}
                        fetchContent={fetchContent}
                        contentData={contentData}
                    />
                    <SocialUrl
                        name="Facebook"
                        logo={facebook}
                        fetchContent={fetchContent}
                        contentData={contentData}
                    />
                    <SocialUrl
                        name="Twitter"
                        logo={twitter}
                        fetchContent={fetchContent}
                        contentData={contentData}
                    />
                    <SocialUrl
                        name="Website"
                        logo={website}
                        fetchContent={fetchContent}
                        contentData={contentData}
                    />
                    <SocialUrl
                        name="Youtube"
                        logo={youtube}
                        fetchContent={fetchContent}
                        contentData={contentData}
                    />
                    <SocialUrl
                        name="LinkedIn"
                        logo={LinkedIn}
                        fetchContent={fetchContent}
                        contentData={contentData}

                    />



                </div>
                <div className="col-12 p-2 mt-2">
                    <div className="train_table">
                        <div className="table-area mt-3">
                            <div className="table-responsive">
                                <table className="table widget-table">
                                    <tr>
                                        <th></th>
                                        <th>Type</th>
                                        <th>URL</th>
                                        <th>Action</th>
                                    </tr>
                                    {contentData.length > 0 ?
                                        contentData.map((curlElem, index) => {
                                            return (
                                                <tr>
                                                    <td><img src={curlElem.socialType === "Instagram" ? instaLogo : curlElem.socialType === "Facebook" ? facebook : curlElem.socialType === "Twitter" ? twitter : curlElem.socialType === "Website" ? website : curlElem.socialType === "LinkedIn" ? LinkedIn : ""} alt="" /></td>
                                                    <td>{curlElem.socialType}</td>
                                                    <td><a href={curlElem.socialUrl} target='_blank'>{curlElem.socialUrl.length >= 50 ? curlElem.socialUrl.slice(0, 47) + "..." : curlElem.socialUrl}</a></td>
                                                    <td>
                                                        {+auth.user.isClientAccount === 1 ? null :
                                                            <span className='ms-2' onClick={() => onDelete(curlElem.id)}>
                                                                <FaTrash title='Delete' style={{ color: "#616161", cursor: "pointer" }} />
                                                            </span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={5} className='text-center py-5' >
                                                {loader.fetch ? <><i className='fa fa-spin fa-spinner fetch-loader' /></> : "No social connected to this clone yet!"}
                                            </td>
                                        </tr>

                                    }



                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br> <br> You want to delete this social URL?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={SocialDelete}
            />
        </div >

    )
}

export default CloneSocial
