import React, { useEffect, useState } from 'react';
import { IoAddOutline } from "react-icons/io5";
import EmbedPopup from '../../../CommonComponents/EmbedPopup';
import { useSelector } from 'react-redux';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import InstancesRows from './InstancesRows';
import { themeColor } from '../../../../Global/Global';
import CreateInstancesModal from './CreateInstancesModal';


const CloneInstances = ({ cId }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [instances, setInstances] = useState([])
    const [animation, setAnimation] = useState([])
    const [state, setState] = useState({
        name: "",
        animationName: "animate__shakeX",
        color: themeColor,
        autoSubmit: false
    })
    const [loader, setLoader] = useState({ fetch: false })

    let ebScript = `<script src="https://backend.aiduals.com/assets/clone_bot.js?id=${cId}&uId=${auth.user.id}&name=${state.name}&an=${state.animationName}&at=${state.autoSubmit}&cl=${state.color?.replace("#", "")}" id="clone_bot"></script>`

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const performHandleChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const handleSubmit = () => {
        handleShow2()
        handleClose()
    }

    const fetchInstances = () => {
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-instance", data, setInstances, loader, setLoader))
    }

    const fetchAnimation = () => {
        dispatch(fetchData("fetch-animation", {}, setAnimation, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchInstances()
        fetchAnimation()
    }, [])


    return (

        <div className="settings_wrap ps-3">
            <div className="row">
                <div className="col-lg-12">
                    <div className="settings_head d-flex justify-content-between align-items-center">
                        <div className="instance_title">
                            <h2>Clone Instances</h2>
                            {/* <p className='para_text2 f-13'>Find out more about the examples here. </p> */}
                        </div>
                        <div className="btn-wrap">
                            {+auth.user.isClientAccount === 1 ? null :
                                <button className='theme-btn' onClick={handleShow}><span>Create Instance<IoAddOutline className=' addIcon' /></span>  </button>
                            }
                        </div>

                    </div>
                </div>

            </div>
            <div className="col-lg-12 p-2 mt-4">
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>S.no</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                                {instances.length > 0 ?
                                    instances.map((curlElem, index) => {
                                        return (
                                            <React.Fragment>
                                                <InstancesRows
                                                    curlElem={curlElem}
                                                    index={index}
                                                    instances={instances}
                                                    setInstances={setInstances}
                                                    animation={animation}
                                                    fetchInstances={fetchInstances}
                                                />
                                            </React.Fragment>

                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4} className='text-center py-5' >
                                            {loader.fetch ? <><i className='fa fa-spin fa-spinner fetch-loader' /></> : "No instances created yet!"}
                                        </td>
                                    </tr>

                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <CreateInstancesModal
                type="Create"
                show={show}
                handleClose={handleClose}
                animation={animation}
                performHandleChange={performHandleChange}
                performFunction={handleSubmit}
            />


            <EmbedPopup
                script={ebScript}
                handleClose={handleClose2}
                handleShow={handleShow}
                fetchInstances={fetchInstances}
                show={show2}
                state={state}
                setState={setState}
                cId={cId}
            />

        </div>




    )
}

export default CloneInstances
