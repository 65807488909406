import React, { useState } from 'react'
import { FiUpload } from "react-icons/fi";
import InstructionModal from './InstructionModal';
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { themeColor } from '../../../../Global/Global';
import { useDispatch } from 'react-redux';
import { onAcceptImage, onGenerateCharacter } from '../../../../Redux/Actions/CloneSettingAction';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const CreateChar = () => {

  const dispatch = useDispatch();
  let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
  const location = useLocation()
  const { cId } = queryString.parse(location.search)
  const [percent, setPercent] = useState(0);
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const [loader, setLoader] = useState({
    submit: false,
    upload: false,
    accept: false
  })

  const [state, setState] = useState({
    image: "",
    aiImage: "",
    prompt: ""
  })

  const fetchImgMedia = (url, name) => {
    let obj = {
      target: {
        name: name,
        value: url
      }
    }
    handleChange(obj)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((preData) => { return { ...preData, [name]: value } })
  }

  const onImageChange = (e) => {
    const formData = new FormData()
    if (e.target.files.length > 0) {
      if (allowedExt.includes(e.target.files[0].type)) {
        if (e.target.files[0].size < 5000000) {
          formData.append('upload_type', "images")
          formData.append('file', e.target.files[0])
          dispatch(onUploadMedia(formData, fetchImgMedia, loader, setLoader, setPercent, "image"))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size for images is 5MB!',
            confirmButtonColor: themeColor
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have Selected Invalid File Type!',
          confirmButtonColor: themeColor
        })
      }
    }
  }

  const handleAcceptImage = () => {
    setLoader({ ...loader, accept: true })
    let data = {
      id: cId,
      enable : true,
      url: state.aiImage.outputUrl
    }
    dispatch(onAcceptImage(data, false, loader, setLoader))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader({ ...loader, submit: true })
    let data = {
      cId: cId,
      text: state.prompt,
      input: state.image
    };
    dispatch(onGenerateCharacter(data, state, setState, loader, setLoader))
  }

  return (
    <>
      <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
        <form className="container-fluid" onSubmit={handleSubmit}>
          <div className="row justify-content-lg-start justify-content-between">
            <div className="col-7">
              <div className="train_content_head trainContentHead">
                <div className=''>
                  <h2>Create your own</h2>
                </div>
              </div>
            </div>
            <div className="col-5">
              <button className='theme-btn' type='button' onClick={handleShow} ><span>Instruction</span></button>
            </div>
          </div>
          {/* <div className="row mt-5 flex-lg-row flex-column-reverse">
            <div className="col-xl-6 col-12 mt-lg-0 mt-4">
              <div className='upload-box'>
                <div className="upload">
                  <div>
                    <FiUpload />
                    <p>Upload image here</p>
                    <span style={{ fontSize: "12px", padding: "0 10px" }}>Recommend size of the image should be 1024X 1024 only</span>
                    <p style={{ paddingTop: "4px" }}>
                      {loader.upload ?
                        <span style={{ color: themeColor }}> {percent} <i className='fa fa-spin fa-spinner' style={{ color: themeColor }} /></span>
                        : ""}
                      <input
                        type="file"
                        onChange={(e) => onImageChange(e)}
                        accept='image/*'
                      />
                    </p>
                  </div>
                  {
                    state.image ?
                      <div className='ms-4'>
                        <img style={{ height: "80px" }} src={state.image} alt="" />
                      </div>
                      : ""
                  } 
                </div>
              </div>
            </div>

            

          </div> */}
          <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="input-outer">
                <label className='label' htmlFor="">Generate Image from Prompt</label>
                <textarea
                  className="common-inp alt mt-2"
                  type="text"
                  placeholder="Enter prompt here"
                  name='prompt'
                  value={state.prompt}
                  rows={5}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='text-end mt-2'>
              </div>
              <div className='text-end mt-4'>
                <button type='submit' disabled={loader.submit} className='theme-btn'>
                  <span>{loader.submit ? <>Submitting <i className='fa fa-spin fa-spinner' /></> : "Submit"}</span>
                </button>
              </div>
            </div>

            {state.aiImage ?
              <div className="col-lg-4 col-md-6 col-12 mt-xl-0 mt-3">
                <div className="upload_img">
                  <img src={`${state.aiImage.outputUrl}`} alt="" />
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <button type='button' disabled={loader.accept} className='theme-btn' onClick={handleAcceptImage}>
                    <span>{loader.accept ? <>Accept <i className='fa fa-spin fa-spinner ms-2' /></> : "Accept"}</span>
                  </button>
                </div>
              </div>
              : ""
            }

          </div>
        </form>

        <InstructionModal
          show={show}
          handleClose={handleClose}
        />

      </div>
    </>
  )
}

export default CreateChar