import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { themeColor } from '../../../../Global/Global';
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import dummyProfile from '../../../../assets/images/dummyImage.png'
import { onChangeCloneSettingProfile, onCheckCustomDomain, onSaveCloneProfile } from '../../../../Redux/Actions/CloneSettingAction';
import { useSelector } from 'react-redux';
import { setAlert } from '../../../../Redux/Actions/AlertActions';
import { IoIosCheckmarkCircle } from "react-icons/io";

const CloneProfile = (props) => {

    const settingData = useSelector(state => state.cloneSetting.data)
    const auth = useSelector(state => state.auth)

    let dummy = "https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg"

    const dispatch = useDispatch();
    const [percent, setPercent] = useState(0);
    const [buttonText, setButtonText] = useState("Copy URL");
    const [loader, setLoader] = useState({
        save: false,
        upload: false,
        check: false
    });
    const [isValidDomain, setIsValidDomain] = useState("")
    const [showAdd, setShowAdd] = useState(false)

    const getProfileImage = (url, type) => {
        dispatch(onChangeCloneSettingProfile(type, url))
    }

    const onInputChange = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    setLoader({ ...loader, upload: true })
                    dispatch(onUploadMedia(formData, getProfileImage, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeCloneSettingProfile(name, value))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    const handleCopy = () => {
        if (settingData.handleUrl) {
            setButtonText("Copied URL")
            navigator.clipboard.writeText(`https://app.aiduals.com/chat/${settingData.handleUrl.replaceAll(/[# ]/g, "-")}?uId=${auth.user.id}&cId=${settingData.id}`);
            setTimeout(() => {
                setButtonText("Copy URL")
            }, 5000)
        } else {
            dispatch(setAlert("Please add clone handle first", "danger"))
        }
    }

    const handleCheck = () => {
        if (settingData.customDomain) {
            let data = {
                cId: settingData.id,
                name: settingData.customDomain
            }
            const dotCount = settingData.customDomain.split(".").length - 1;
            if ((dotCount === 2 || dotCount === 1) && !settingData.customDomain[0].includes(".") && !settingData.customDomain[settingData.customDomain.length - 1].includes(".")) {
                setLoader({
                    ...loader,
                    check: true
                })
                dispatch(onCheckCustomDomain(data, setIsValidDomain, loader, setLoader))
            } else {
                if ((dotCount <= 2 && dotCount >= 0)) {
                    dispatch(setAlert("Not a valid domain.", "danger"))
                } else {
                    dispatch(setAlert("Not more then two dot.", "danger"))
                }
            }
        } else {
            dispatch(setAlert("Please provide domain to check!", "danger"))
        }

        // setDomainLoader(true)
        // if (domainData.domainBtnType === "Check") {
        //     const { name } = e.target;
        //     if (settingData.customDomain !== "") {
        //         let obj = { url: settingData.customDomain }

        //         const dotCount = settingData.customDomain.split(".").length - 1;
        //         if (dotCount === 1 && !settingData.customDomain[0].includes(".") && !settingData.customDomain[settingData.customDomain.length - 1].includes(".")) {
        //             dispatch(onCheckCustomDomain(name, obj, domainData, setDomainData, false));
        //         } else {
        //             setDomainData({ ...domainData, domainCheck: false, domainMsg: "Not more then one dot.", });
        //             setDomainLoader(false)
        //         }
        //     } else {
        //         setDomainLoader(false)
        //     }
        // } else {
        //     dispatch(onSubmitDomain(state.id, state.domain, domainData, setDomainData, domainLoader, setDomainLoader));
        // }
    };

    return (
        <>

            <div className="settings_wrap ps-3">
                <form action="" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <div className="settings_head">
                                <h2>Clone profile</h2>
                            </div>
                            <div className="setting_upload_img mt-5">
                                <span>
                                    {loader.upload ?
                                        <div className='profileLoader'>
                                            <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "23px" }} />
                                        </div>
                                        : <img src={settingData.image || dummyProfile} alt="no image" />
                                    }

                                </span>

                                {+auth.user.isClientAccount === 1 ? null :
                                    <div className="setting_upload_input ms-4">
                                        <button className='off-btn'>Upload new image</button>
                                        <input
                                            type="file"
                                            accept='image/*'
                                            onChange={onInputChange}
                                        />
                                    </div>
                                }
                            </div>


                        </div>
                        <div className="col-xxl-9 col-xl-7 col-12 mt-5">
                            <div className="settings_content">
                                <h3 className='mb-1'>Clone Name</h3>
                                <p className='mb-2 para_text2 f-13'>You own this URL completely. It should ideally match the name and/or handle you use on other platforms.</p>
                                <div className="setting_input">
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter Clone Name Here"
                                            name='name'
                                            value={settingData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="settings_content mt-5">
                                <h3 className='mb-1'>Clone Handle</h3>
                                <p className='mb-2 para_text2 f-13'>This particular URL is yours. This should ideally match your handle or moniker on another website, such as X/Instagram.</p>
                                <div className="setting_input">
                                    <div className="row">
                                        <div className="col-xxl-8 col-xl-12">
                                            <div className="input-outer">
                                                <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                                    <span class="input-group-text" id="basic-addon3">https://app.aiduals.com/chat</span>
                                                    <input
                                                        className="common-inp alt"
                                                        type="text"
                                                        placeholder="Enter Handle Here"
                                                        name='handleUrl'
                                                        value={settingData.handleUrl}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-12">
                                            <button type='button' className="theme-btn bdr ms-xxl-3 ms-0 mt-xxl-0 mt-3" onClick={handleCopy}><span>{buttonText}</span></button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="settings_content mt-5">
                                <h3 className='mb-1'>Custom Domain</h3>
                                <div className="setting_input">
                                    <div className="input-outer">
                                        <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Enter Custom Domain"
                                                name='customDomain'
                                                value={settingData.customDomain}
                                                onChange={handleChange}
                                            />

                                            {+auth.user.isClientAccount === 1 ? null :
                                                <button
                                                    type='button'
                                                    className='theme-btn'
                                                    style={{ width: 180, background: isValidDomain === "valid" ? "green" : isValidDomain === "invalid" ? "#b30a0a" : "" }}
                                                    onClick={handleCheck}
                                                >
                                                    <span className='me-1'>{loader.check ? <>Checking <i className='fa fa-spinner fa-spin' /></> : "Check"}</span>
                                                </button>
                                            }

                                        </div>

                                    </div>
                                </div>

                                <span
                                    style={{
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        fontSize: "14px",
                                        color: themeColor,
                                        fontFamily: "poppins,Sans-serif",
                                    }}
                                    onClick={() => setShowAdd(!showAdd)}
                                >
                                    How to Add a Custom Domain
                                </span>
                                {showAdd ?
                                    <div className="add_on_domain">
                                        <ul className="list_domain">
                                            <li>
                                                Please use top-level domains only (sub-domains
                                                won’t work).
                                            </li>
                                            <li>
                                                Please enter your domain above without any www, http or https (domain.com for example).
                                            </li>
                                            <li>
                                                Enter your domain name for example "mydomain.com"
                                                (without the quotation marks) and click the "Save" button.
                                            </li>
                                            <li>
                                                Copy this IP address for the A Record:
                                                72.52.158.199
                                            </li>
                                            <li>
                                                Log into your domain register account and find
                                                the option to add Host Records, which can usually
                                                be found in the Advanced DNS section. If you're
                                                struggling to find this, perform a Google search
                                                for "[Domain Registrar Here] how to change host
                                                records".
                                            </li>
                                            <li>Select your type as "A Record".</li>
                                            <li>Set Host as "@".</li>
                                            <li>
                                                Paste the copied IP Address from step 4 into the
                                                value field.
                                            </li>
                                            <li>Leave TTL as Automatic or 14400.</li>
                                            <li>Click Save and you're done.</li>
                                        </ul>
                                        <span>
                                            Note: It can take up to 48-72 hours to reflect.
                                        </span>
                                    </div> : null}
                            </div>
                            <div className="setting_btn text-end mt-3">
                                {+auth.user.isClientAccount === 1 ? null :
                                    <button type='submit' disabled={loader.save} className='theme-btn me-0'><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span></button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CloneProfile
