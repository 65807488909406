import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { onSaveCloneProfile, onUpdateSetting } from '../../../../Redux/Actions/CloneSettingAction'

const ResponsePreferences = () => {
    const dispatch = useDispatch()
    const settingData = useSelector(state => state.cloneSetting.data)
    const auth = useSelector(state => state.auth)

    const [lang, setLang] = useState([])
    const [loader, setLoader] = useState({
        save: false,
    });

    const fetchLang = () => {
        dispatch(fetchData("get-response-language", {}, setLang, false, false, false, "shortDataLg"))
    }

    const handleUpdatePreferance = (name, value) => {
        dispatch(onUpdateSetting(name, value))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onUpdateSetting(name, value))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }


    useEffect(() => {
        fetchLang()
    }, [])

    return (
        <div>
            <div className="settings_wrap">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Response Preferences</h2>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-3">
                        <div className="settings_content">
                            <h3 className='mb-1' style={{ color: "#000", fontWeight: "500" }}>Default Language</h3>
                            <p className='mb-2 para_text2 f-13'>Which languages is your clone native to?</p>
                        </div>
                        <div className="slectBoxWrap">
                            <select
                                className='frequencyBox'
                                name='language'
                                onChange={handleChange}
                                value={settingData.language}
                            >
                                {lang.length > 0 ?
                                    lang.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.code} >{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className=" settings_content  mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Preference Bias </h3>
                        <p className="para_text2 f-13">Give the most recent document priority.</p>
                    </div>
                    <div className="opt-select">
                        <label className="switch">
                            <input
                                type="checkbox"
                            // checked={selectedSlide.faceNarration.enable}
                            // onChange={handleChangeEnable}
                            />
                            <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="col-lg-7 mt-3">
                <div className="row align-items-center">
                    <div className=" settings_content  mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Creativity</h3>
                        {/* <p className="para_text2 f-13">How strictly the clone adhereas to its content</p> */}
                    </div>
                    <div className="inp_radio_wrap mt-2">
                        <div className="d-flex justify-content-between clone-style-txt">
                            <p className=' f-13 default' onClick={() => handleUpdatePreferance("creativity", 60)} style={{ fontWeight: "500", cursor: "pointer", color: "#ff6a27" }}>Default</p>
                            <p style={{ fontWeight: "600", textAlign: "end" }}>{settingData.creativity}</p>
                        </div>
                        <div className='range_wrap'>
                            <input
                                type="range"
                                className="mt-2"
                                min="0"
                                max="100"
                                id="customRange2"
                                name='creativity'
                                value={settingData.creativity}
                                onChange={(e) => handleUpdatePreferance(e.target.name, e.target.value)}

                            />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Low Creativity / High Strictness </p>
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>High Creativity / Low Strictness</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 mt-3">
                <div className="row align-items-center">
                    <div className=" settings_content   mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Response Length</h3>

                        {/* <p className="para_text2 f-13">Average number of words in a replay</p> */}
                    </div>
                    <div className="inp_radio_wrap mt-2">
                        <div className="d-flex justify-content-between  clone-style-txt">
                            <p className='f-13 default' onClick={() => handleUpdatePreferance("responseLength", 30)} style={{ fontWeight: "500", cursor: "pointer", color: "#ff6a27" }}>Default</p>
                            <p style={{ fontWeight: "600", textAlign: "end" }}>{settingData.responseLength}</p>


                        </div>
                        <div className='range_wrap'>
                            <input
                                type="range"
                                className="mt-2"
                                min="0"
                                max="100"
                                id="customRange"
                                name='responseLength'
                                value={settingData.responseLength}
                                onChange={(e) => handleUpdatePreferance(e.target.name, e.target.value)}

                            />
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Sort & Concise</p>
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Long & Thorough</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-5">
                    {+auth.user.isClientAccount === 1 ? null :
                        <button onClick={handleSubmit} className="theme-btn me-0"><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span>  </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default ResponsePreferences;
