import React from 'react'
import { Nav, Button, Tab } from 'react-bootstrap';
import AllCharacters from './AllCharacters';
import CreateChar from './CreateChar';

const Characters = () => {
    return (
        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Characters</h2>
                </div>
                <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div className="col-xl-6 col-12 ">
                        <Nav variant="pills" className="welcome-studio justify-content-lg-start justify-content-between">
                            <Nav.Item>
                                <Nav.Link eventKey="first">3D Characters</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Create Your Own</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-12">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <AllCharacters />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <CreateChar />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>

                </Tab.Container>
            </div>

        </>
    )
}

export default Characters
