import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onChangeMessage, onSaveCloneProfile } from '../../../../Redux/Actions/CloneSettingAction';

const Messages = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const settingsData = useSelector(state => state.cloneSetting.data)
    const autoresponder = useSelector(state => state.social.autoresponder)
    const [loader, setLoader] = useState({
        save: false,
    });

    const handleChangeMesages = (e) => {
        const { name, value } = e.target
        dispatch(onChangeMessage(name, value))
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingsData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    const fetchAutoList = () => {
        // dispatch(fetchData("fetch-autoresponder-list", {}, setResponderList))
    }

    useEffect(() => {
        fetchAutoList()
    }, [])

    return (
        <div className='row'>
            <form className="message_wrap" onSubmit={handleSubmit}>
                <div className="col-12">
                    <div className="settings_head">
                        <h2>Message</h2>
                    </div>
                </div>
                <div className="col-lg-7 mt-5">
                    <div className="settings_content ">
                        <h3 className='mb-1 ' style={{ fontWeight: "500" }}>Initial Message</h3>
                        <p className='mb-2 para_text2 f-13'>How your clone initiates every discussion</p>
                    </div>
                    <div className="setting_input message">
                        <div className="input-outer">
                            <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                <textarea
                                    className="common-inp alt"
                                    rows="4"
                                    type="text"
                                    name='initialMessage'
                                    placeholder="Hy, How can i Help ?"
                                    value={settingsData.defaultMessage.initialMessage}
                                    onChange={handleChangeMesages}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 mt-5">
                    <div className="settings_content ">
                        <h3 className='mb-1' style={{ fontWeight: "500" }}>Message on unknown answer</h3>
                        <p className='mb-2 para_text2 f-13'>When your clone is unsure about how to answer a user's question, this is what it will say.</p>
                    </div>
                    <div className="setting_input msg">
                        <div className="input-outer">
                            <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                <textarea
                                    className="common-inp alt"
                                    rows="4"
                                    type="text"
                                    name='unknownAnswer'
                                    placeholder="Sorry not sure about that one. Fell free to short me an email at..."
                                    value={settingsData.defaultMessage.unknownAnswer}
                                    onChange={handleChangeMesages}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 mt-5">
                    <div className="settings_content">
                        <h3 className='mb-1' style={{ fontWeight: "500" }}>Message before Email Capture </h3>
                        <p className='mb-2 para_text2 f-13'>The number of messages a user can send before having to check in and enter their email address.</p>
                    </div>
                    <div className="inp_radio_wrap">
                        {/* <p style={{ textAlign: "end", fontWeight: "600" }}>{settingsData.defaultMessage.emailCapture}</p> */}

                        <input type="number" className="common-inp mt-2"
                            name='emailCapture'
                            id="customRange2"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            min={0}
                            value={settingsData.defaultMessage.emailCapture}
                            onChange={handleChangeMesages}
                            required
                        />
                    </div>
                </div>

                <div className="col-lg-7 mt-5">
                    <div className="settings_content">
                        <h3 className='mb-1' style={{ fontWeight: "500" }}>Autoresponder </h3>
                        <p className='mb-2 para_text2 f-13'>Select the autoresponder in which the email information will be saved.</p>
                    </div>
                    <div className="inp_radio_wrap">
                        <div className='d-flex justify-content-between'>
                            <select
                                className='common-inp'
                                name="autoresponderId"
                                value={settingsData.defaultMessage.autoresponderId}
                                onChange={handleChangeMesages}
                            >
                                <option>Select Autoresponder List</option>
                                {autoresponder.length > 0 ?
                                    autoresponder.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.id}>{curElem.username}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-lg-7">
                    {/* <div className=" mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 style={{ color: "#000", fontWeight: "500" }}>Enable Voice Calling</h3>
                                <p className="para_text2 f-13">Gives the option to call clone. It is necessary to upload a voice.</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 style={{ color: "#000", fontWeight: "500" }}>Enable Image Sending</h3>
                                <p className="para_text2 f-13">Give user the option to send photos to your clone</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-end mt-3">
                        {+auth.user.isClientAccount === 1 ? null :
                            <button type='submit' className="theme-btn me-0"><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span>  </button>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Messages
