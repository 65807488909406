import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { GiSparkles } from "react-icons/gi";
import { FaPlusCircle } from 'react-icons/fa';
import { MdOutlineCancel } from "react-icons/md";
import { onChangeCloneSettingBio, onCreateTopic, onSaveCloneBio, onSaveCloneProfile } from '../../../../Redux/Actions/CloneSettingAction';
import { themeColor } from '../../../../Global/Global';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchData, onDeleteCampaign, onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import { RxCross2 } from 'react-icons/rx';
import { useSelector } from 'react-redux';
import { setAlert } from '../../../../Redux/Actions/AlertActions';
import SweetAlert from '../../../CommonComponents/SweetAlert';

const CloneBio = (props) => {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.cloneSetting.data)
    const auth = useSelector(state => state.auth)
    const [org, SetOrg] = useState(false);
    const [topics, setTopics] = useState([]);
    const [customTopics, setCustomTopics] = useState([]);

    const [isEditable, setIsEditable] = useState(false);
    const [percent, setPercent] = useState(0);
    const [loader, setLoader] = useState({
        save: false,
        upload: false,
        create: false
    });

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false,
        id: false
    })




    const handleCreateTopic = (e) => {
        const { value } = e.target;
        if (value && isEditable === true) {
            let data = {
                cId: settingData.id,
                name: value
            }
            if ((topics.findIndex(({ name }) => name.toLowerCase() === data.name.toLowerCase()) === -1) && customTopics.findIndex(({ name }) => name.toLowerCase() === data.name.toLowerCase()) === -1) {
                setLoader({ ...loader, create: true })
                dispatch(onCreateTopic(data, fetchCustomTopic, loader, setLoader))
                setIsEditable(false)
            } else {
                dispatch(setAlert("This topic is already exists please provide a different one!", "danger"))
            }
        }
    };

    const handleAdd = (name) => {
        if (settingData.topics.includes(name)) {
            let newTopics = settingData.topics.filter((curElem) => {
                return curElem !== name
            })
            dispatch(onChangeCloneSettingBio("topics", newTopics))
        } else {
            if (settingData.topics.length >= 5) {
                dispatch(setAlert("You can add maximum 5 topics", "danger"))
            } else {
                let data = [...settingData.topics]
                data.push(name)
                dispatch(onChangeCloneSettingBio("topics", data))
            }

        }
    }

    const getProfileImage = (url, type) => {
        dispatch(onChangeCloneSettingBio(type, url))
    }

    const onInputChange = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    setLoader({ ...loader, upload: true })
                    dispatch(onUploadMedia(formData, getProfileImage, loader, setLoader, setPercent, "profile"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeCloneSettingBio(name, value))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (settingData.topics.length > 0) {
            setLoader({ ...loader, save: true })
            let data = { ...settingData }
            dispatch(onSaveCloneProfile(data, loader, setLoader))
        } else {
            dispatch(setAlert("You can add maximum 5 topics", "danger"))
        }
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false,
            id: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id,
            cId: settingData.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-custom-topic", data, customTopics, setCustomTopics, setSweet))
    }


    const fetchTopics = () => {
        dispatch(fetchData("fetch-topics", {}, setTopics))
    }

    const fetchCustomTopic = () => {
        let data = {
            cId: settingData.id
        }
        dispatch(fetchData("fetch-custom-topic", data, setCustomTopics))
    }




    useEffect(() => {
        if (settingData.id) {
            fetchCustomTopic()
        }
    }, [settingData.id])

    useEffect(() => {
        fetchTopics()
    }, [])

    return (
        <div>
            <div className="settings_head">
                <h2>Clone Bio</h2>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="col-xl-7 col-md-9 col-12 mt-5">
                    <div className="settings_content">
                        <h3>HeadLine</h3>
                        <p className='mb-2 para_text2'>Displayed in several locations. Like the headline on LinkedIn. Maximum of 120 characters.</p>
                        <div className="setting_input">
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    placeholder="HeadLine"
                                    name='headLine'
                                    value={settingData.headLine}
                                    onChange={handleChange}
                                    maxLength={120}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings_content mt-5">
                        <div className="d-flex  justify-content-start flex-wrap mt-2 mb-2">
                            <h3>Description</h3>
                        </div>
                        <p className='mb-2 para_text2'>A more detailed description of this clone will appear on the profile page of your clone.</p>
                        <div className="setting_input">
                            <div className="input-outer">
                                <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                    <textarea
                                        className="common-inp alt"
                                        rows="4"
                                        type="text"
                                        placeholder="Description"
                                        name='description'
                                        value={settingData.description}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-xl-7 col-md-9 col-12 mt-4">
                    <div className="settings_content">
                        <h3 className=''>Topics</h3>
                        <p className='mb-2 para_text2 f-13'>Choose 1–5 subjects that are pertinent to the area of competence of your clone. Aids in the computation of the readiness score.</p>
                        <div className='topics_wrap mt-3'>
                            {topics.length > 0 ?
                                topics.map((curElem, index) => {
                                    return (
                                        <div className="me-3 mb-3" key={index}>
                                            <div className={`topics_elm text-center text-capitalize ${settingData.topics.includes(curElem.name) ? "active-topic" : ""}`} onClick={() => handleAdd(curElem.name)}><i className={`fa-solid me-2 ${curElem.icon}`}></i>{curElem.name}</div>
                                        </div>
                                    )
                                })
                                : ""
                            }

                            {customTopics.length > 0 ?
                                customTopics.map((curElem, index) => {
                                    return (
                                        <div className="me-3 mb-3" style={{ position: "relative" }} key={index}>
                                            <div className={`topics_elm text-center ${settingData.topics.includes(curElem.name) ? "active-topic" : ""}`} onClick={() => handleAdd(curElem.name)}>{curElem.name} <MdOutlineCancel style={{ fontSize: 17, cursor: "pointer" }} onClick={() => onDelete(curElem.id)} /></div>

                                            {/* <MdOutlineCancel style={{ position: "absolute", top: 10, right: 9, fontSize: 17, color: settingData.topics.includes(curElem.name) ? themeColor : "#71717a", cursor: "pointer" }} onClick={() => onDelete(curElem.id)} /> */}

                                            <SweetAlert
                                                show={sweet.enable}
                                                message={"<b>Are you sure?</b> <br> <br> You want to delete this topic?"}
                                                confirmButtonColor={""}
                                                cancelButtonColor={""}
                                                confirmButtonName={sweet.confirmButtonName}
                                                alertLoader={sweet.loader}
                                                cancelButtonName={"Cancel"}
                                                handleClose={onCancelDelete}
                                                performDelete={performDelete}
                                            />
                                        </div>
                                    )
                                }) : ""
                            }
                            {loader.create ?
                                <div className='mt-2'>
                                    <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "13px" }} />
                                </div>
                                : ""
                            }
                        </div>


                        <div className="setting_btn text-start mt-4">
                            {isEditable ?
                                <div style={{ position: "relative" }}>
                                    <input
                                        type="text"
                                        name='content'
                                        placeholder='Enter Topic'
                                        className='common-inp'
                                        onKeyUp={(event) =>
                                            event.key === "Enter" ? handleCreateTopic(event) : null
                                        }
                                    />
                                    <RxCross2 onClick={() => setIsEditable(false)} className='crossInput' size={20} />
                                </div>
                                :
                                +auth.user.isClientAccount === 1 ? null :
                                    <button type='button' className='off-btn me-0' style={{ color: "#71717a" }} onClick={() => setIsEditable(true)}><span> <FaPlusCircle className="me-2" />Add your own</span></button>

                            }
                        </div>
                    </div>
                    <div className="setting_btn text-end mt-4">
                        {+auth.user.isClientAccount === 1 ? null :
                            <button type='submit' disabled={loader.save} className='theme-btn me-0'><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span></button>
                        }
                    </div>
                </div>
            </form>

            {/* <div className="col-xl-7 col-md-9 col-12">
                <div className="settings_content mt-3">
                    <h3 className=''>Organizations</h3>
                    <p className='mb-2 para_text2' >Add organization your clone is associated with such as a company, nonprofit, or school</p>
                    <div className='org_wrap mt-3'>
                        <h3 className='org_head' style={{ padding: "10px 20px" }}> <i class="fa-solid fa-flag pe-2"></i>Added organization</h3>
                        <div className="org_body_content">
                            <div className='org_body'>
                                <div className="img-Wrap py-3 text-center">
                                    <img src={noteImg} alt="note" className='img-fluid' />
                                </div>
                                <div className="btn-wrap text-center pb-3">

                                    <button className='theme-btn' onClick={() => SetOrg(true)}> <span>Add Organization</span>
                                    </button>
                                </div>
                            </div>

                            <div className="org_footer row justify-content-center pt-2 pb-2">
                                <p className=' col-lg-6 text-center'>You can drag and drop and choose the ordering of the organization displayed and you profile</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
            <Modal className='theme-modal video-modal feed' show={org} onHide={() => SetOrg(false)} centered>
                <Modal.Body className="p-0">
                    <div className='modalClose text-end' onClick={() => SetOrg(false)}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>

                    <div className="row">
                        <div className="col-12">
                            <div className="settings_head">
                                <h2>Clone profile</h2>
                            </div>
                            <div className="setting_upload_img mt-5">
                                <span> <img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></span>
                                <div className="setting_upload_input ms-4">
                                    <button className='off-btn'>Upload new image</button>
                                    <input type="file" />
                                </div>
                            </div>


                        </div>
                        <div className="col-12 mt-5">
                            <div className="settings_content">
                                <h3 className='mb-1'>Clone Name</h3>
                                <p className='mb-2 para_text'>This is your unique url. Ideally, this should match your name and/or your handle on another</p>
                                <div className="setting_input">
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="email"
                                            placeholder="Dana"
                                            name='email'
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </div >
    )
}

export default CloneBio
